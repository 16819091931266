/* eslint-disable no-console */
import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";
import vuetify from "./vuetify";

import VueGtm from "vue-gtm";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

Vue.config.productionTip = false;

Vue.use(VueRouter);

import axios from "axios";

import application from "./application.vue";

import navigation from "./component/navigation.vue";
import menu from "./component/menu.vue";

import automatic from "./component/automatic.vue";

import login from "./component/login.vue";
import forgotPassword from "./component/forgotPassword.vue";
import resetPassword from "./component/resetPassword.vue";
import registration from "./component/registration.vue";
import registrationConfirmation from "./component/registrationConfirmation.vue";
import termCondition from "./component/termCondition";

import dashboard from "./component/dashboard.vue";

import profile from "./component/profile.vue";
import bdmProfile from "./component/bdmProfile.vue";
import bdmResources from "./component/bdmResources.vue";
import agencySupport from "./component/agencySupport.vue";
import personal from "./component/personal.vue";
import company from "./component/company.vue";
import accountSetting from "./component/accountSetting.vue";
import faqs from "./component/faqs.vue";
import virtualCard from "./component/virtualCard.vue";

import duoSales from "./component/duoSales.vue";
import duoResources from "./component/duoResources.vue";
import videoResources from "./component/videoResources.vue";

import approveChangeRequest from "./component/approveChangeRequest";

import duoTraining from "./component/duoTraining";
import duoTrainingModulePage from "./component/duoTrainingModulePage";
import duoToolkit from "./component/duoToolkit";
import seeMoreNz from "./component/seeMoreNz";

import quiz from "./component/quiz";
import quizBanner from "./component/quizBanner.vue";
import quizTC from "./component/quizTC.vue";
import christchurchCalling from "./component/christchurchCalling.vue";
import christchurchCallingTC from "./component/christchurchCallingTC.vue";
import newSkiLand from "./component/newSkiLand.vue";
import newSkiLandTC from "./component/newSkiLandTC.vue";
import luxeInLisbon from "./component/luxeInLisbon.vue";
// import destinationDash from "./component/destinationDash";
// import destinationDashLanding from "./component/destinationDashLanding";
// import destinationDashTermCondition from "./component/destinationDashTermCondition";
// import destinationDashAuckland from "./component/destinationDashAuckland";
// import destinationDashQueenstown from "./component/destinationDashQueenstown";
// import destinationDashChristchurch from "./component/destinationDashChristchurch";
import discoverNorthAmerica from "./component/discoverNorthAmerica";
import nzSki from "./component/nzSki";
import newYork from "./component/newYork";
import cabinOfPossibility from "./component/cabinOfPossibility";
import agencyCampaignIncentive from "./component/agencyCampaignIncentive";
import EconomyStretch from "./component/EconomyStretch";
import TimeOutBeforeTakeOff from "./component/TimeOutBeforeTakeOff";

//gama
import adminTrainingList from "./component/training/adminTrainingList.vue";

//gama new
import adminTrainingModuleList from "./component/adminTraining/moduleList.vue";
import adminModuleCreateEdit from "./component/adminTraining/moduleCreateEdit.vue";
import adminItemCreateEdit from "./component/adminTraining/itemCreateEdit.vue";

import administrationNavigation from "./component/administrationNavigation.vue";
import administrationMenu from "./component/administrationMenu";
import administrationLogin from "./component/administrationLogin";
import administrationDashboard from "./component/administrationDashboard";
import administrationTrainingModule from "./component/administrationTrainingModule";
import administrationTrainingModuleConfiguration from "./component/administrationTrainingModuleConfiguration";
import administrationPageConfiguration from "./component/administrationPageConfiguration";
import administrationBdm from "./component/administrationBdm";
import administrationAgency from "./component/administrationAgency";
import administrationPerson from "./component/administrationPerson";
import administrationReport from "./component/administrationReport";
import administrationNotification from "./component/administrationNotification";
import administrationAgentInfo from "./component/administrationAgentInfo";
import administrationAgentInfoDetails from "./component/administrationAgentInfoDetails";
import administrationToolkitReport from "./component/administrationToolkitReport";
import administrationDestinationDashReport from "./component/administrationDestinationDashReport";
import administrationChristmasChaseReport from "./component/administrationChristmasChaseReport";
import administrationScoreSpotReport from "./component/administrationScoreSpotReport";
import administrationVisitBdmReport from "./component/administrationVisitBdmReport";
import administrationSales from "./component/administrationSales";
import administrationSalesReport from "./component/administrationSalesReport";
import administrationTileDashboardCreator from "./component/administrationTileDashboardCreator";
import administrationTileTrainingCreator from "./component/administrationTileTrainingCreator";
import administrationTileCreatorView from "./component/administrationTileCreatorView.vue";
import administrationLandingPageCreator from "./component/administrationLandingPageCreator";
import administrationLandingPageCreatorEdit from "./component/administrationLandingPageCreatorEdit";
import administrationFormCreator from "./component/administrationFormCreator";
import administrationFormCreatorEdit from "./component/administrationFormCreatorEdit";
import administrationFormCreatorReport from "./component/administrationFormCreatorReport";
import administrationFormCreatorReportDetails from "./component/administrationFormCreatorReportDetails";
import administrationFormCreatorReportSubmissionDetails from "./component/administrationFormCreatorReportSubmissionDetails";
import administrationContentCreatorAccessReport from "./component/administrationContentCreatorAccessReport";
import travelManagersConference from "./component/travelManagersConference";
import MtaTravelAugustIncentive from "./component/MtaTravelAugustIncentive.vue";
import creditsAndRefunds from "./component/creditsAndRefunds";
import seatsToSuit from "./component/seatsToSuit";
import seatsToSuitTC from "./component/seatsToSuitT&C";

import landingPageView from "./component/landingPageView";
import administrationTileVideoCreator from "./component/administrationTileVideoCreator";


const router = new VueRouter({
	routes: [
		// agent portal
		{
			path: "",
			component: navigation,
			props: true,
			children: [
				{ path: "/", component: automatic, props: true },
				{ path: "/login", component: login, props: true },
				{ path: "/registration", component: registration, props: true },
				{ path: "/registration/confirmation", component: registrationConfirmation, props: true },
				{ path: "/password/forgot", component: forgotPassword, props: true },
				{ path: "/password/reset", component: resetPassword, props: true },
				{ path: "/terms-and-conditions", component: termCondition, props: true },
				// refactor
				{ path: "/approve/request/change/:agencyChangeRequestId/:agencyChangeRequestTextId", component: approveChangeRequest, props: true }
			]
		},
		{
			path: "",
			component: menu,
			props: true,
			children: [
				{ path: "/dashboard", component: dashboard, props: true, meta : { role: "Agent" }},
				{ path: "/profile", component: profile, props: true, meta : { role: "Agent" } },
				{ path: "/profile/personal", component: personal, props: true, meta : { role: "Agent" } },
				{ path: "/profile/company", component: company, props: true, meta : { role: "Agent" } },
				{ path: "/profile/accountsetting", component: accountSetting, props: true, meta : { role: "Agent" } },
				{ path: "/bdm", component: bdmProfile, props: true, meta : { role: "Agent" } },
				{ path: "/agency", component: agencySupport, props: true, meta : { role: "Agent" } },
				{ path: "/sales", component: duoSales, props: true, meta : { role: "Agent" } },
				{ path: "/resources", component: duoResources, props: true, meta : { role: "Agent" } },
				{ path: "/resources/bdm", component: bdmResources, props: true, meta : { role: "Agent" } },
				{ path: "/resources/videos", component: videoResources, props: true, meta : { role: "Agent" } },
				{ path: "/training", component: duoTraining, props: true, meta : { role: "Agent" } },
				{ path: "/training/module/:moduleId", component: duoTrainingModulePage, props: true, meta : { role: "Agent" } },
				{ path: "/TradeToolkit", component: duoToolkit, props: true, meta : { role: "Agent" } },
				{ path: "/seeMoreNZ", component: seeMoreNz, props: true, meta : { role: "Agent" } },
				{ path: "/quiz", component: quiz, props: true, meta : { role: "Agent" } },
				{ path: "/USAGetaway", component: quizBanner, props: true, meta : { role: "Agent" } },
				{ path: "/quizTC", component: quizTC, props: true, meta : { role: "Agent" } },
				{ path: "/discoverNorthAmerica", component: discoverNorthAmerica, props: true, meta : { role: "Agent" } },
				{ path: "/faqs", component: faqs, props: true, meta : { role: "Agent" } },
				{ path: "/virtualCard", component: virtualCard, props: true, meta : { role: "Agent" } },
				{ path: "/nzSki", component: nzSki, props: true, meta : { role: "Agent" } },
				{ path: "/newYork", component: newYork, props: true, meta : { role: "Agent" } },
				{ path: "/cabinOfPossibility", component: cabinOfPossibility, props: true, meta : { role: "Agent" } },
				{ path: "/mtaTravelIncentive", component: agencyCampaignIncentive, props: true, meta : { role: "Agent" } },
				{ path: "/travelManagersIncentive", component: agencyCampaignIncentive, props: true, meta : { role: "Agent" } },
				{ path: "/travelManagersConference", component: travelManagersConference, props: true, meta : { role: "Agent" } },
				{ path: "/EconomyStretch", component: EconomyStretch, props: true, meta : { role: "Agent" } },
				{ path: "/TimeOutBeforeTakeOff", component: TimeOutBeforeTakeOff, props: true, meta : { role: "Agent" } },
				{ path: "/MTATravelAugustIncentive", component: MtaTravelAugustIncentive, props: true, meta : { role: "Agent" } },
				{ path: "/CreditsAndRefunds", component: creditsAndRefunds, props: true, meta : { role: "Agent" } },
				{ path: "/SeatsToSuit", component: seatsToSuit, props: true, meta : { role: "Agent" } },
				{ path: "/SeatsToSuit/TermsAndConditions", component: seatsToSuitTC, props: true, meta : { role: "Agent" } },
				{ path: "/ChristchurchCalling", component: christchurchCalling, props: true, meta : { role: "Agent" } },
				{ path: "/ChristchurchCallingTC", component: christchurchCallingTC, props: true, meta : { role: "Agent" } },
				{ path: "/NewSkiLand", component: newSkiLand, props: true, meta : { role: "Agent" } },
				{ path: "/NewSkiLandTC", component: newSkiLandTC, props: true, meta : { role: "Agent" } },
				{ path: "/LuxeInLisbon", component: luxeInLisbon, props: true, meta : { role: "Agent" } },
				{ path: "/page/:slug", component: landingPageView, props: true, meta: { role: "Agent" } },
			]
		},
		// administration portal
		{
			path: "",
			component: administrationNavigation,
			props: true,
			children: [
				{ path: "/administration", component: administrationLogin, props: true },
				{ path: "/administration/login", component: administrationLogin, props: true }
			]
		},
		{
			path: "",
			component: administrationMenu,
			props: true,
			children: [
				//gama

				{ path: "/administration/trainingList", component: adminTrainingList, props: true, meta: { restricted: true } },

				//gama new

				{ path: "/administration/training/list/module", component: adminTrainingModuleList, props: true, meta: { restricted: true } },
				{ path: "/administration/training/:operation/module", component: adminModuleCreateEdit, props: true, meta: { restricted: true } },
				{ path: "/administration/training/module/:operation/:item", component: adminItemCreateEdit, props: true, meta: { restricted: true } },

				{ path: "/administration/agency", component: administrationAgency, props: true, meta: { restricted: true } },
				{ path: "/administration/agency/:agencyId/person", component: administrationPerson, props: true, meta: { restricted: true } },
				{ path: "/administration/agent/info", component: administrationAgentInfo, props: true, meta: { restricted: true } },
				{ path: "/administration/agent/info/:personId/details", component: administrationAgentInfoDetails, props: true, meta: { restricted: true } },
				{ path: "/administration/bdm", component: administrationBdm, props: true, meta: { restricted: true } },
				{ path: "/administration/trainingModule/:moduleId/page/:pageId/configuration", component: administrationPageConfiguration, props: true, meta: { restricted: true, roles: ["super_user"] } },
				{ path: "/administration/trainingModule/:moduleId/configuration", component: administrationTrainingModuleConfiguration, props: true, meta: { restricted: true, roles: ["super_user"] } },
				{ path: "/administration/trainingModule", component: administrationTrainingModule, props: true, meta: { restricted: true, roles: ["super_user"] } },
				{ path: "/administration/dashboard", component: administrationDashboard, props: true, meta: { restricted: true, roles: ["super_user", "admin"] } },
				{ path: "/administration/report", component: administrationReport, props: true, meta: { restricted: true, roles: ["super_user", "admin"] } },
				{ path: "/administration/toolkit", component: administrationToolkitReport, props: true, meta: { restricted: true, roles: ["super_user", "admin"] } },
				{ path: "/administration/destinationDash", component: administrationDestinationDashReport, props: true, meta: { restricted: true, roles: ["super_user", "admin"] } },
				{ path: "/administration/christmasChase", component: administrationChristmasChaseReport, props: true, meta: { restricted: true, roles: ["super_user", "admin"] } },
				{ path: "/administration/scoreSpot", component: administrationScoreSpotReport, props: true, meta: { restricted: true, roles: ["super_user", "admin"] } },
				{ path: "/administration/visitbdm", component: administrationVisitBdmReport, props: true, meta: { restricted: true, roles: ["super_user", "admin"] } },
				{ path: "/administration/notification", component: administrationNotification, props: true, meta: { restricted: true, roles: ["super_user"] } },
				{ path: "/administration/sales", component: administrationSales, props: true, meta: { restricted: true, roles: ["super_user"] } },
				{ path: "/administration/sales/report", component: administrationSalesReport, props: true, meta: { restricted: true, roles: ["super_user"] } },
				{ path: "/administration/creator/tile/dashboard", component: administrationTileDashboardCreator, props: true, meta: { restricted: true, roles: ["super_user"] } },
				{ path: "/administration/creator/tile/dashboard/:id", component: administrationTileCreatorView, props: true, meta: { restricted: true, roles: ["super_user"] } },
				{ path: "/administration/creator/tile/training", component: administrationTileTrainingCreator, props: true, meta: { restricted: true, roles: ["super_user"] } },
				{ path: "/administration/creator/tile/training/:id", component: administrationTileCreatorView, props: true, meta: { restricted: true, roles: ["super_user"] } },
				{ path: "/administration/creator/page/landing", component: administrationLandingPageCreator, props: true, meta: { restricted: true, roles: ["super_user"] } },
				{ path: "/administration/creator/page/landing/:id", component: administrationLandingPageCreatorEdit, props: true, meta: { restricted: true, roles: ["super_user"] } },
				{ path: "/administration/creator/form", component: administrationFormCreator, props: true, meta: { restricted: true, roles: ["super_user"] } },
				{ path: "/administration/creator/form/:id", component: administrationFormCreatorEdit, props: true, meta: { restricted: true, roles: ["super_user"] } },
				{ path: "/administration/creator/accesslog", component: administrationContentCreatorAccessReport, props: true, meta: { restricted: true, roles: ["super_user", "admin"] } },
				{ path: "/administration/form", component: administrationFormCreatorReport, props: true, meta: { restricted: true, roles: ["super_user", "admin"] } },
				{ path: "/administration/form/:id/details", component: administrationFormCreatorReportDetails, props: true, meta: { restricted: true, roles: ["super_user", "admin"] } },
				{ path: "/administration/form/:id/details/:personId/submission", component: administrationFormCreatorReportSubmissionDetails, props: true, meta: { restricted: true, roles: ["super_user", "admin"] } },
				{ path: "/administration/creator/tile/video", component: administrationTileVideoCreator, props: true, meta: { restricted: true, roles: ["super_user"] } },
				{ path: "/administration/creator/tile/video/:id", component: administrationTileCreatorView, props: true, meta: { restricted: true, roles: ["super_user"] } },
			]
		}
	]
});

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.role === "Agent")) {
		// Dual condition due to strange rules on login do not remove
		if (store.state.principal.administrator === false) {
			next();
			return;
		}
		
		// Used for all logged in users
		if (store.state.principal.administrator) {
			next();
			return;
		}
		next("/login");
	} else if (to.matched.some(record => record.meta.restricted)) {
		if(store.state.principal.administrator === true && to.meta.roles && !to.meta.roles.includes(store.state.principal.role)){
			return false;
		} else if (store.state.principal.administrator === true) {
			next();
			return;
		}
		next("/administration/login");
	} else {
		next();
	}
});

var sentryKey = "https://338685c4fe0c4deface7b185fa72c5cf@sentry.io/2291215";
//var sendHitTask = true;
//var analyticsId = "UA-267171755-1";
//var ga4gtagId = "G-ZC47CTSJ27";

var gtmId = "GTM-TNM2V47Z";
if (window.location.origin.includes("duo.airnzagent.com.au")) {
	sentryKey = "https://cc328fb9c765474abac4bdd3955ae687@sentry.io/2291227";
	//sendHitTask = true;
	//analyticsId = "UA-151634809-7";
	//ga4gtagId = "G-B1V2KGE8YP";
	gtmId = "GTM-NLGCP4D";
}


Vue.use(VueGtm, {
		id: gtmId,
		defer: false, 
		compatibility: false, 
		enabled: true,
		debug: true,
		loadScript: true, 
		trackOnNextTick: false,
		vueRouter: router,
	});

axios.defaults.baseURL = "";

if (window.location.origin.includes("localhost")) {
	axios.defaults.withCredentials = true;
	axios.defaults.baseURL = "http://localhost:8900/duo";
}

Vue.prototype.$base = axios.defaults.baseURL;

Vue.prototype.$window = window;

if (!window.location.origin.includes("localhost")) {
	Sentry.init({
		dsn: sentryKey,
		integrations: [new Integrations.Vue({ Vue, attachProps: true })]
	});
}

if (window.location.origin.includes("duo.airnzagent.com.au")) {
    Vue.prototype.$bucket = "https://airnzduo.s3-ap-southeast-2.amazonaws.com";
	Vue.prototype.$tinyMceKey = "uxbpkk3d252218fdsl506e11jrh82knhb5k56gv7suj9mrln";
} else {
	Vue.prototype.$bucket = "https://airnzduo-stage.s3-ap-southeast-2.amazonaws.com";
	Vue.prototype.$tinyMceKey = "fvvdp2de72a8fy0iynvmbseaf6tat0lifq5ceqow40fappti";
}

Vue.prototype.$tinyMceKey = "uxbpkk3d252218fdsl506e11jrh82knhb5k56gv7suj9mrln";

/* eslint-disable */
new Vue({
	router,
	store,
	vuetify,
	render: display => display(application),
	data() {
		return {
			monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
			loader: { count: 0, stroke: 7, diameter: 50, value: false },
			emailPattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		};
	},
	created: function () {
		axios.interceptors.request.use(
			request => {
				this.load();
				return request;
			},
			error => {
				this.unload();

				let notification = { title: "Unknown error", content: "" };
				if (error.response) {
					if (error.response.status === 400) {
						notification.title = "Bad request";
					} else if (error.response.status === 500) {
						notification.title = "Server error";
					} else if (error.response.status === 401) {
						notification.title = "Unauthorized";
					} else if (error.response.status === 403) {
						notification.title = "Forbidden";
					} else if (error.response.status === 404) {
						notification.title = "Not found";
					} else {
						notification.title = "Notification (" + error.response.status + ")";
						window.alert("Unknown error has occurred, please forward this information to Duo support team: " + JSON.stringify(error));
					}
					notification.content = error.response.data;
				}
				store.commit("showNotification", notification);

				return Promise.reject(error);
			}
		);

		axios.interceptors.response.use(
			response => {
				this.unload();

				if (response.data && response.data.type === "NOTIFICATION") {
					store.commit("showNotification", { title: "Notification", content: response.data });
				}

				return response;
			},
			error => {
				this.unload();

				let notification = { title: "Unknown error", content: "" };
				if (error.response) {
					if (error.response.status === 400) {
						notification.title = "Bad request";
					} else if (error.response.status === 500) {
						notification.title = "Server error";
					} else if (error.response.status === 401) {
						notification.title = "Unauthorized";
					} else if (error.response.status === 403) {
						notification.title = "Forbidden";
					} else if (error.response.status === 404) {
						notification.title = "Not found";
					} else if (error.response.status === 409) {
						notification.title = "Error";
					} else {
						notification.title = "Notification (" + error.response.status + ")";
						window.alert("Unknown error has occured, please forward this information to Duo support team: " + JSON.stringify(error));
					}
					notification.content = error.response.data;
				}

				// hide notification exception for specific case(s)
				if (error.response) {
					if (error.response.data.errorList[0] !== 'Sorry, the email address or password are incorrect') {
						store.commit("showNotification", notification);
					}
				} else {
					// just in case if the request is totally failed (not sent to the server)
					store.commit("showNotification", notification);
				}
				
				return Promise.reject(error);
			}
		);
	},
	methods: {
		load() {
			this.loader.count++;
			this.loader.value = true;
		},
		unload() {
			this.loader.count--;
			if (this.loader.count < 0) {
				this.loader.count = 0;
			}
			this.loader.value = this.loader.count > 0;
		},
		clone(object) {
			return JSON.parse(JSON.stringify(object));
		},
		
		ellipsifyHtml(text, limit) {
			if (text) {
				text = text.replace(/(<([^>]+)>)/gi, "");
			}
			
			if (text && text.length > limit) {
				return (text.substring(0, limit) + "...");
			}
			return text;
		},
		ellipsify(text, limit) {
			if (text && text.length > limit) {
				return text.substring(0, limit) + "...";
			}
			return text;
		},
		escapeHtml(text) {
			if (text) {
				text = text.replace(/(<([^>]+)>)/gi, "");
			}

			return text;
		},
		textify(text, before, after) {
			if (text && text.trim().length > 0) {
				return (before ? before : "") + text + (after ? after : "");
			}
			return "";
		},
		enum(text) {
			if (text) {
				return text.replace(/_/g, " ");
			}
			return text;
		},
		asset(text, resolution) {
			if (text) {
				if (text.toLowerCase().startsWith("http")) {
					return text;
				} else {
					return axios.defaults.baseURL + "/asset/" + resolution + "/" + text;
				}
			}
			return null;
		},
		projection(list, array) {
			for (let item of list) {
				for (let property in item) {
					if (!array.includes(property)) {
						delete item[property];
					}
				}
			}
		},
		capitalizeFirst(text) {
			return text && text[0].toUpperCase() + text.slice(1);
		},
		clean(object) {
			for (var propName in object) {
				if (!object[propName] || typeof object[propName] !== "object") {
					if (typeof object[propName] === "string" && this.isBlank(object[propName])) {
						delete object[propName]; // If string is blank, then delete
					}
					continue // If null or not an object, skip to the next iteration
				}

				// The property is an object
				this.clean(object[propName]); // Make a recursive call on the nested object
				if (this.isBlank(object[propName])) {
					delete object[propName]; // The object had no properties, so delete that property
				}
			}
		},
		copyWith(newValue, source) {
			// clone source object with new value ( newValue can be Json, ex. {height:400} )
			var target = this.clone(source);
			return Object.assign(target, newValue);
		},
		isBlank(object) {
			// check object/string has children
			for (var propName in object) {
				return false;
			}
			// no children and object is numeric/boolean
			if (typeof (object) === 'number' || typeof (object) === 'boolean') {
				return false;
			}
			// blank object: [], {}, "", null, undefined
			return true;
		},
		isSvg(src) {
			if (src) {
				let q = src.toLowerCase();
				return q.endsWith('.svg');
			}
			return false;
		},
		parseDate(date) {
			if (!date) return "";
			let day = date.substr(8, 10);
			let monthNumber = parseInt(date.substring(5, 7)) - 1;
			let year = date.substring(0, 4);
			return day + " " + this.monthNames[monthNumber] + " " + year;
		},
		disablePastDates(val) {
			return val >= new Date().toISOString().slice(0, 10)
		},
		saveActivityLog(entity) {
			entity.personId = store.state.principal.id;
			axios
				.post("/system/activitylog/save", {
					entity: entity,
				})
				.then(() => {})
				.catch(() => {});
		},
		saveDestinationDashLog(entity) {
			entity.personId = store.state.principal.id;
			axios
				.post("/system/destinationdashlog/save", {
					entity: entity,
				})
				.then(() => {})
				.catch(() => {});
		},
		isAllowedRegEmail(v){
			return (v.includes("@gmail.") || v.includes("@outlook.") || v.includes("@hotmail.") || (v.includes("@bigpond.")) && v.endsWith("*"));
		},
		isAllowedEmail(v){
			return v.includes("@gmail.") || v.endsWith("@outlook.") || v.includes("@hotmail.") || v.includes("@bigpond.");
		},
		validateEmailPattern(v){
			if (v.length === 0 || !this.emailPattern.test(v)) {
                return false;
            } else {
                return true;
            }
		},
		convertVideoUrl(url) {
			if (!url.includes("vimeo.com/video/") && !url.includes("youtube.com/embed/")) {
				let validUrl = url.startsWith("https://");

				if(validUrl && url.includes("youtube.com/watch?v=")) {
					return url.replace("/watch?v=", "/embed/")
				} 	else if (validUrl && url.includes("youtu.be/")) {
					return url.replace("youtu.be/", "youtube.com/embed/");
				} else if (validUrl && url.includes("vimeo.com/")) {
					return url.replace("/vimeo.com/", "/player.vimeo.com/video/");
				}
			}
			
			return url;
		},
		trimSpaces(value) {
			return value ? value.replace(/\s+/g, ' ').trim() : '';
		},
		openRouteInNewTab(path) {
			window.open(this.$router.resolve(path).href, '_blank');
		},
		isURL(str) {
			let url;
	  
			try {
			  url = new URL(str);
			} catch (_) {
			  return false;
			}
	  
			return url.protocol === "http:" || url.protocol === "https:";
		  },
	}
}).$mount("#application");
